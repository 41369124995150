@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
body,
html {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif;
  height: 100%;
  margin: 0;
  background: #fff1e5;
}

header,
main,
footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  max-width: 1000px;
  padding: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #e76f51; /* Slightly darkened color for better contrast */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 4em;
}

p {
  font-size: 1.8em;
}

img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.initial-content h1,
.initial-content h2 {
  margin-bottom: 20px;
}

.flyr-icon {
  display: block;
  margin: 0 auto;
  width: 150px;
}

.initial-content {
  padding-top: 40px; /* Add top padding */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20vh;
}

.content-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20vh;
}

.content-section img,
.content-section .section-heading {
  flex: 1;
}

.section-a img {
  order: 0;
}

.section-c img {
  order: 0;
}

.section-b img {
  order: 2;
}

.section-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-primary-heading {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.section-secondary-heading {
  font-size: 1.4em;
  opacity: 0.9;
  margin-top: 0;
}

/* New styles for MainContent responsive design */
.main-content {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.main-heading {
  font-size: 4.5rem;
  font-weight: 700;
  text-align: center;
  margin-top: 5rem;
}

.sub-heading {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5em;
  text-align: center;
  opacity: 0.9;
  margin-top: 6rem;
  margin-bottom: 8rem;
}

.section-image {
  max-width: 100%;
  height: auto;
}

.support-email-container {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

@media only screen and (max-width: 768px) {
  .main-heading {
    font-size: 2.5rem;
    margin-top: 2.5rem;
  }

  .sub-heading {
    font-size: 1rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .content-section {
    flex-direction: column;
    margin-bottom: 10vh;
  }

  .section-primary-heading {
    font-size: 2rem;
    text-align: center;
  }

  .section-secondary-heading {
    font-size: 1rem;
    text-align: center;
  }

  .section-a img,
  .section-b img,
  .section-c img,
  .section-d img,
  .section-e img {
    order: 1;
    margin-bottom: 1.5rem;
  }

  .support-email-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .flyr-icon {
    width: 100px;
  }

  .buttons-container {
    margin-top: 1.5rem;
  }
}

@media only screen and (max-width: 600px) {
  main {
    padding: 10px;
  }

  .content-section {
    flex-direction: column;
  }

  .section-heading {
    margin-bottom: 20px;
  }

  .section-a img,
  .section-b img,
  .section-c img,
  .section-d img,
  .section-e img {
    order: 1;
    margin-bottom: 1rem;
  }

  .main-heading {
    font-size: 2rem;
  }

  .sub-heading {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 1024px) {
  .content-section img {
    /* Reduce the image width to 70% of its original size */
    flex: 0.6;
    max-width: 60%;
  }

  .content-section .section-heading,
  .content-section .section-text {
    /* Allow the text content to take up the remaining space */
    flex: 1.4;
  }
}

/* Style for both buttons */
.custom-style {
  width: 60%; /* Mobile optimized width */
  height: auto;
  transition: transform 0.3s ease;
  margin: 5px 0; /* Vertical spacing for mobile layout */
  display: block; /* To ensure each button takes the full width on mobile */
}

.custom-style:hover {
  transform: scale(1.05);
}

/* If the package uses images inside the button, you might want to adjust the image styles */
.custom-style img {
  width: 25%;
  height: auto;
  display: block;
}

/* Styles for Tablet */
@media only screen and (min-width: 601px) and (max-width: 1023px) {
  .custom-style {
    width: 40%; /* Adjust width for tablets */
    margin: 0 10px; /* Horizontal spacing for tablet layout */
    display: inline-block; /* Revert back to inline block to ensure they are side by side */
  }
}

/* Styles for Desktop */
@media only screen and (min-width: 1024px) {
  .custom-style {
    width: 170px; /* Fixed width for desktops */
  }
}

.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* To ensure buttons wrap to next line if space is limited */
  gap: 20px;
  margin-top: 2rem;
}

.support-email-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.support-email-container span {
  font-size: 20px;
  margin: 0;
  color: #e76f51;
  opacity: 0.9; /* Match opacity of 'No folding, no bundling. Just hustle.' */
}

.support-email {
  font-size: 20px;
  color: #e76f51;
  text-decoration: underline;
  margin-top: 5px;
  opacity: 0.9;
}

/* Container for buttons with relative positioning for popup */
.buttons-container-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

/* Redesign notification as a popup */
.availability-notification {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #e76f51;
  color: white;
  padding: 10px 16px;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 0.9rem;
  max-width: 90%;
  width: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 10;
  animation: fadeInDown 0.3s ease-in;
}

/* Add a little arrow at the bottom */
.availability-notification:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #e76f51 transparent transparent transparent;
}

/* Change animation for popup style */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/* Remove previous animation since we're using a new one */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles for the notification popup */
@media only screen and (max-width: 768px) {
  .availability-notification {
    font-size: 0.85rem;
    padding: 8px 12px;
    max-width: 95%;
  }
}

/* Update button container styles to work with buttons instead of anchors */
.store-button-wrapper {
  background: none;
  border: none;
  padding: 0;
  margin: 5px;
  cursor: pointer;
  display: inline-block;
}

.store-button-wrapper:focus {
  outline: 2px solid #e76f51;
  border-radius: 8px;
}

/* Permanent availability message styling */
.availability-message {
  color: #e76f51;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin: 20px 0 10px 0;
  padding: 0 15px;
}

@media only screen and (max-width: 768px) {
  .availability-message {
    font-size: 14px;
    margin: 15px 0 5px 0;
  }
}

/* Navigation styles - updated to be less prominent with right-aligned links */
.navigation {
  width: 100%;
  background-color: #fff1e5; /* Match the background color */
  padding: 10px 0;
  box-shadow: none;
  position: relative;
  z-index: 100;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo-area {
  flex: 0 0 auto;
}

.nav-logo {
  height: 40px;
  width: auto;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.nav-link {
  color: #e76f51;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.nav-link:hover {
  background-color: rgba(231, 111, 81, 0.1);
}

.nav-link.active {
  background-color: rgba(231, 111, 81, 0.2);
  color: #e76f51;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .nav-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .nav-links {
    justify-content: center;
    width: 100%;
  }

  .nav-link {
    font-size: 13px;
    padding: 5px 10px;
  }
}

/* Updated Footer styles with app buttons */
.footer {
  width: 100%;
  padding: 30px 0;
  background-color: #fff1e5; /* Match site background */
  margin-top: 50px;
  border-top: 1px solid rgba(231, 111, 81, 0.1); /* Lighter border */
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  flex-wrap: wrap;
  gap: 60px; /* Add significant gap between left and right sections */
}

.footer-left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-right {
  display: flex;
  align-items: center;
}

.footer-app-buttons .buttons-container {
  display: flex;
  gap: 15px;
  margin-top: 0; /* Override the margin-top from the main buttons container */
}

/* Remove footer-specific button styles since we're using the same ones as in main content */

.footer-text {
  color: #e76f51;
  opacity: 1; /* More subtle text */
  font-size: 14px;
}

.footer-contact-button {
  display: inline-block;
  background-color: #e76f51;
  color: white;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(231, 111, 81, 0.3);
}

.footer-contact-button:hover {
  background-color: #d96348;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(231, 111, 81, 0.4);
  text-decoration: none;
  color: white;
}

@media only screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }

  .footer-left {
    order: 2;
  }

  .footer-right {
    order: 1;
  }

  .footer-app-buttons .buttons-container {
    flex-direction: row;
  }
}

@media only screen and (max-width: 600px) {
  .footer-left {
    gap: 15px;
  }

  .footer-text {
    order: 2;
  }

  .footer-contact {
    order: 1;
  }
}
